@import "./../styles/variables.scss";

.hero-ctn {
  position: relative !important;
  height: 85vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
  padding: 0px !important;
  margin: 0px !important;
  background: #f7e5e7;
  border-bottom: 5px solid #2d2d2d;
}
.no-padding {
  padding: 0px !important;
  margin: 0px !important;
}
.hero-img {
  position: absolute !important;
}
.hero-asset-1 {
  width: 120px;
  min-width: 120px;
  opacity: 0.9;
}
@media only screen and (max-width: 768px) {
  .hero-asset-1 {
    width: 100px;
    min-width: 100px;
  }
}
@media only screen and (max-width: 425px) {
  .hero-asset-1 {
    width: 80px;
    min-width: 80px;
  }
}
.hero-asset-2 {
  width: 300px;
  min-width: 300px;
}

@media only screen and (max-width: 768px) {
  .hero-asset-2 {
    width: 150px;
    min-width: 150px;
  }
}
@media only screen and (max-width: 425px) {
  .hero-asset-2 {
    width: 150px;
    min-width: 150px;
  }
}
.hero-asset-3 {
  width: 580px;
  min-width: 580px;
}
@media only screen and (max-width: 768px) {
  .hero-asset-3 {
    width: 300px;
    min-width: 300px;
  }
}
@media only screen and (max-width: 425px) {
  .hero-asset-3 {
    width: 300px;
    min-width: 300px;
  }
}
.hero-asset-4 {
  width: 1000px;
  min-width: 1000px;
  opacity: 0.8;
}
@media only screen and (max-width: 768px) {
  .hero-asset-4 {
    width: 600px;
    min-width: 600px;
  }
}
@media only screen and (max-width: 425px) {
  .hero-asset-4 {
    width: 510px;
    min-width: 510px;
  }
}
.hero-asset-5 {
  width: 1225px;
  min-width: 1225px;
}
@media screen and (max-width: 768px) {
  .hero-asset-5 {
    width: 750px;
    min-width: 750px;
  }
}
@media screen and (max-width: 425px) {
  .hero-asset-5 {
    width: 640px;
    min-width: 640px;
  }
}

.hero-asset-6 {
  width: 1440px;
  min-width: 1440px;
}
@media screen and (max-width: 768px) {
  .hero-asset-6 {
    width: 850px;
    min-width: 850px;
  }
}
@media screen and (max-width: 425px) {
  .hero-asset-6 {
    width: 740px;
    min-width: 740px;
  }
}

.hero-asset-7 {
  width: 1800px;
  min-width: 1800px;
  opacity: 0.85;
}

@media screen and (max-width: 768px) {
  .hero-asset-7 {
    width: 950px;
    min-width: 950px;
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .hero-asset-7 {
    width: 800px;
    min-width: 800px;
    display: none;
  }
}
.max-width {
  max-width: none !important;
}
.rotate-1 {
  animation: rotation-1 35s infinite linear !important;
}

@keyframes rotation-1 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-2 {
  animation: rotation-2 35s infinite linear !important;
}

@keyframes rotation-2 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-359deg);
  }
}

.rotate-3 {
  animation: rotation-3 160s infinite linear !important;
}

@keyframes rotation-3 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-4 {
  animation: rotation-4 70s infinite linear !important;
}

@keyframes rotation-4 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-5 {
  animation: rotation-5 100s infinite linear !important;
}

@keyframes rotation-5 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-6 {
  animation: rotation-6 90s infinite linear !important;
}

@keyframes rotation-6 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-359deg);
  }
}

.rotate-7 {
  animation: rotation-7 200s infinite linear !important;
}

@keyframes rotation-7 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

// *#dm *.dmBody div.u_1702342005
// {
// }
// *#dm *.dmBody div.u_1702342005
// {
// }
.profile__ctn {
  margin: 0 auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #2d2d2d;
  position: relative;
}
.profile__info-ctn {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding-top: 15vh;
}

.profile__intro-1 {
  margin: 1rem;
}

@media only screen and (max-width: 900px) {
  .profile__intro-1 {
    margin: 8rem 0rem;
  }
}

.profile__ctn-inner {
  display: flex;
  margin: 0rem;
  max-width: 1030px;
  justify-content: center;
  font-family: "Tropiline";
}

@media only screen and (max-width: 1024px) {
  .profile__ctn-inner {
    margin: 0rem 6rem;
  }
}

@media only screen and (max-width: 900px) {
  .profile__ctn-inner {
    margin: 0rem 1rem;
  }
}

@media only screen and (max-width: 623px) {
  .profile__ctn-inner {
    margin: 0rem 1rem 5rem 1rem;
  }
}

@media only screen and (max-width: 900px) {
  .profile__ctn-inner {
    flex-wrap: wrap;
  }
}

.profile__intro-2 {
  font-family: "Tropiline Light";
  color: white;
  font-size: 12pt;
  letter-spacing: 0.3px;
  line-height: 29px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.profile__profile-img-ctn {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 623px) {
  .profile__intro-1 {
    display: none;
  }
}

.profile__intro {
  font-size: 16pt;
  font-family: "Tropiline Black";
  width: 100%;
  line-height: 35px;
  color: #f7e5e7;
}

.profile__txt-ctn {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 50%;
  color: #f7e5e7;
  font-family: "Tropiline Light";
}

@media only screen and (max-width: 956px) {
  .profile__txt-ctn {
    width: 100%;

  }
}

@media only screen and (max-width: 900px) {
  .profile__intro{
    text-align: center;
  }

  .profile__intro-2{
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  .button-mobile {
    display: flex;
    justify-content: center;
  }
}

.profile {
  &__circle-animation {
    width: 160px;
    height: 160px;
    max-width: 160px;
    min-width: 160px;
    max-height: 160px;
    min-height: 160px;
    border: 1.5px solid #f7e5e7;
    border-radius: 50%;
    position: relative;
    bottom: 21px;
    right: 210px;
    display: flex;
    justify-content: center;
  }

  &__profile-img {
    z-index: 2;
    width: 280px;
    position: relative;
    left: 170px;
  }

  &__circle-animation:hover {
    transform: scale(1.7) translate(0px, 1px);
    background: #f7e5e7;
  }

  &__profile-img-ctn:hover .profile__circle-animation {
    transform: scale(1.7) translate(0px, 1px);
    background: #f7e5e7;
  }

  &__dots {
    width: 30px;
    position: absolute;
    top: 50%;
  }

  &__hi {
    width: 40px;
    position: absolute;
    top: 40%;
    opacity: 0;
  }

  &__profile-img-ctn:hover .profile__dots {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  &__profile-img-ctn:hover .profile__hi {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  &__button {
    border: 1px solid #f7e5e7;
    font-family: "Tropiline";
    max-width: 150px;
    border-radius: 3px;
    padding: 13px 10px 10px 10px;
    display: flex;
    justify-content: center;
    color: #f7e5e7;
    text-decoration: none;
    margin: 1rem 0rem;
    z-index: 1;
    transition: all 0.5s ease-in-out;
  }

  &__button:hover {
    background: #f7e5e7;
    color: #2d2d2d;
  }

  &__footer {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    background: #1c1c1c;
    height: 200px;
    padding: 1rem;
  }

  &__scroll-down {
    position: absolute;
    top: -5px;
    animation: bounce 1s infinite;
    background: none;
    border: none;
    display: none;

    @media only screen and (min-width: 623px) {
      display: block;
    }
  }

  &__scroll-img {
    width: 20px;
    height: 20px;
    padding: 2rem;
  }

  @media screen and (max-width: 768px){
    &__scroll-img {
      display:none;
    }
  }

  &__footer-txt {
    color: white;
    font-family: "Tropiline Light";
    opacity: 0.8;
    font-size: 10pt;
  }
}

#ease-out {
  transition: 0.3s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(1px);
  }
  50% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(1px);
  }
}
