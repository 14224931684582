@import "./../styles/variables.scss";

.orca {
  &__page-wrapper {
    background: $background-darkgrey;
    height: 100vh;
    display: flex;
    z-index: -2;
  }

  &__outline {
    display: flex;
    z-index: 10;
    border: $yellow solid 2px;
    margin: 1rem;
  }

  &__info-ctn {
    position: relative;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    justify-content: center;
    z-index: 10;
  }

  &__info-ctn-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8% 0%;
    justify-content: center;
    z-index: 10;
    border: 1px solid #f7f09a21;
    background: #1e1e1ed6;
    width: 54%;
  }

  @media screen and (max-width: 768px) {
    &__info-ctn-inner {
      width: 88%;
    }
  }

  @media screen and (max-width: 425px) {
    &__info-ctn-inner {
      width: 95%;
    }
  }

  &__title {
    color: $yellow;
    font-family: "Tropiline Black";
    font-size: 24pt;
    margin: 1rem;
    text-align: center;
  }
  &__button {
    font-family: "Tropiline";
    width: 150px;
    border: 1px solid $yellow;
    border-radius: 3px;
    padding: 13px 10px 10px 10px;
    display: flex;
    justify-content: center;
    color: $yellow;
    text-decoration: none;
    margin: 1rem;
    z-index: 1;
  }

  &__button:hover {
    background: $blue;
    color: $background-darkgrey;
    border: 1px solid $blue;
    transition: 0.5s;
  }

  &__paragraph {
    color: $yellow;
    font-family: "Tropiline Light";
    font-size: 13pt;
    text-align: center;
    margin: 1rem;
    line-height: 22px;
    width: 70%;
  }

  @media screen and (max-width: 425px) {
    &__paragraph {
      font-size: 11pt;
    }
  }

  &__img-1 {
    position: absolute;
    left: -1%;
    bottom: -19%;
    transform: scale(0.8);
  }

  &__img-2 {
    position: absolute;
    right: -23%;
    bottom: -36%;
    transform: scale(0.9) rotate(8deg);
  }

  &__img-3 {
    position: absolute;
    left: -3%;
    top: -34%;
    transform: scale(0.8) rotate(208deg);
  }

  &__img-ctn {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
  }

  &__page-content {
    width: 100%;
    background: $background-darkgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;
  }

  &__project-ctn-kepler {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(
        rgba(164, 67, 148, 0.333),
        rgba(191, 184, 226, 0.363)
      ),
      url("../assets/images/other-projects/project-kepler.jpg");
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  &__project-ctn-kepler:hover {
    transform: scale(1.1);
  }

  &__project-ctn-communihelp {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(
        rgba(164, 67, 148, 0.333),
        rgba(191, 184, 226, 0.363)
      ),
      url("../assets/images/other-projects/project-communihelp.jpg");
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  &__project-ctn-communihelp:hover {
    transform: scale(1.1);
  }

  &__project-ctn-rally {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(
        rgba(164, 67, 148, 0.333),
        rgba(191, 184, 226, 0.363)
      ),
      url("../assets/images/other-projects/project-rally.png");
    background-size: cover;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  &__project-ctn-rally:hover {
    transform: scale(1.1);
  }

  &__project-ctn-other:hover {
    transform: scale(1.1);
  }

  &__project-ctn-other {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(
        rgba(164, 67, 148, 0.333),
        rgba(191, 184, 226, 0.363)
      ),
      url("../assets/images/other-projects/project-other.jpg");
    background-size: cover;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  // ANIMATION
  &__dots-ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    overflow: hidden;
    margin-top: 3rem;
  }

  &__dots-scroller {
    display: flex;
    animation: scroll 80s linear infinite;
    width: calc(80vw * 4);
  }

  &__dots-divider {
    width: 80vw;
    margin-right: 2px;
    position: relative;
    left: -80vw;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(80vw * 2));
  }
}
