@import "./../styles/variables.scss";

.neighbourhood {
  background:  #f7e5e7;
  display: flex;
  z-index: -2;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    color: #2d2d2d;
  }

  &__title {
    font-family: "Tropiline Black";
    color: #2d2d2d;
  }

  &__description {
    font-family: "Tropiline";
    width: 70%;
  }

  &__image-container {
    width: 80%;
  }
}
