$background-darkgrey: #212121;
$background-medgrey: rgb(52, 52, 52);
$background-litemedgrey: rgb(36, 36, 36);

$blue: #cdeaf7;
$yellow: #f7f09a;
$rallyblue: #012f91;

@font-face {
  font-family: "Tropiline";
  src: local("Tropiline"),
    url("./../assets/fonts/tropiline_regular-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "Tropiline Black";
  src: local("Tropiline Black"),
    url("../assets/fonts/tropiline_black-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "Tropiline Light";
  src: local("Tropiline Light"),
    url("./../assets/fonts/tropiline_light-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "Tropiline Black Italic";
  src: local("Tropiline Black Italic"),
    url("../assets/fonts/tropiline_black_italic-webfont.ttf") format("truetype");
}

button:focus {outline:0;}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.padding-bottom-10 {
  margin-bottom: 10rem;
}