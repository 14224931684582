@import "./../styles/variables.scss";

.communihelp {
  &__page-wrapper {
    background: url("../assets/images/communihelp/communihelp.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    z-index: -2;
  }

  &__outline {
    width: 100%;
    display: flex;
    z-index: 10;
    border: rgba(255, 255, 255, 0.78) solid 2px;
    margin: 1rem 1rem;
  }

  &__info-ctn {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    width: 50%;
  }

  &__info-ctn-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28% 0%;
    justify-content: center;
    z-index: 10;
    background: rgba(255, 255, 255, 0.78);
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    &__info-ctn {
      width: 70%;
    }
  }

  @media screen and (max-width: 525px) {
    &__info-ctn {
      width: 85%;
    }
  }

  &__title {
    color: rgba(3, 94, 92, 0.898);
    font-family: "Tropiline Black";
    font-size: 24pt;
    margin: 1rem;
    text-align: center;
  }
  &__button {
    font-family: "Tropiline";
    border: 1px solid rgba(3, 94, 92, 0.898);
    border-radius: 3px;
    padding: 13px 10px 10px 10px;
    display: flex;
    justify-content: center;
    color: rgba(3, 94, 92, 0.898);
    text-decoration: none;
    margin: 1rem;
    z-index: 1;
  }

  &__button:hover {
    background: rgba(3, 94, 92, 0.898);
    color: rgba(255, 255, 255, 0.78);
    transition: all 0.5s ease-in-out;
  }

  &__comm-img {
    width: 100%;
    margin-bottom: 20px;

    @media only screen and (min-width: 768px) {
      width: 40%;
    }
  }

  &__comm-ctn {
    display: flex;
    width: 70%;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item-ctn {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 2rem 0rem;
    border: 1px rgba(3, 94, 92, 0.898) solid;
    padding: 2rem;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media only screen and (min-width: 1024px) {
      padding: 4rem;
    }
  }

  &__txt-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @media only screen and (min-width: 768px) {
      margin-bottom: 0;
      padding: 0 2rem;
      width: 40%;
    }

    & > a {
      width: 160px;
    }
  }

  &__subtitle {
    color: rgba(3, 94, 92, 0.898);
    font-family: "Tropiline Black";
    font-size: 16pt;
    text-align: center;
    line-height: 22px;
    width: 100%;
  }

  &__page-content {
    width: 100%;
    background: rgba(214, 221, 223, 0.356);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 598px) {
    &__video {
      margin: 1rem 0rem;
      width: 90%;
    }
  }

  &__communihelp {
    font-family: "Tropiline Black";
    color: rgba(3, 94, 92, 1);
    font-size: 20pt;
    margin-bottom: 2rem;
    line-height: 29px;
    text-align: center;
    padding-top: 2rem;
  }

  &__intro-ctn {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }

  @media screen and (max-width: 726px) {
    &__intro-ctn {
      margin: 3rem 0rem 1rem 0rem;
    }
  }

  &__intro {
    color: #023faa;
    font-family: "Tropiline";
    font-size: 13pt;
    line-height: 22px;
    padding: 10px 0rem;
  }

  &__page-content-2 {
    width: 100%;
    background-image: linear-gradient(#bfb8e291, #e2b8da9e);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__info-ctn-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem 1rem;
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    &__info-ctn-1 {
      width: 90%;
    }
  }

  &__paragraph {
    color: rgba(3, 94, 92, 1);
    font-family: "Tropiline";
    font-size: 12pt;
    line-height: 22px;
    text-align: center;
    width: 100%;
    margin: 2rem 0rem;

    @media screen and (max-width: 425px) {
      font-size: 11pt;
    }
  }

  &__full-screen-img {
    width: 70%;
  }

  &__footer {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #1c1c1c;
    height: 200px;
  }

  &__scroll-down {
    position: absolute;
    bottom: 0%;
    right: 46%;
    animation: bounce 1s infinite;
    z-index: 11;
    background: none;
    border: none;
  }

  &__scroll-img {
    width: 20px;
    height: 20px;
    padding: 2rem;
  }

  @media screen and (max-width: 768px){
    &__scroll-img {
      display: none;
    }
  }

  &__other-projects-ctn {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8rem;
    justify-content: center;
  }

  &__project-ctn {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: white;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__project-ctn-kepler {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(
        rgba(67, 74, 164, 0.333),
        rgba(18, 95, 119, 0.363)
      ),
      url("../assets/images/other-projects/project-kepler.jpg");
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  &__project-ctn-kepler:hover {
    transform: scale(1.1);
  }

  &__project-ctn-rally {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(
        rgba(67, 74, 164, 0.333),
        rgba(18, 95, 119, 0.363)
      ),
      url("../assets/images/other-projects/project-rally.png");
    background-size: cover;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  &__project-ctn-rally:hover {
    transform: scale(1.1);
  }

  &__project-ctn-orca {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(
        rgba(67, 74, 164, 0.333),
        rgba(18, 95, 119, 0.363)
      ),
      url("../assets/images/other-projects/project-orca.jpg");
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  &__project-ctn-orca:hover {
    transform: scale(1.1);
  }

  &__project-ctn-other:hover {
    transform: scale(1.1);
  }

  &__project-ctn-other {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(
        rgba(67, 74, 164, 0.333),
        rgba(18, 95, 119, 0.363)
      ),
      url("../assets/images/other-projects/project-other.jpg");
    background-size: cover;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  &____project-title {
    z-index: 10;
  }

  &__project-1-background-image {
    z-index: -10;
  }

  &__project-title {
    font-family: "Tropiline Black";
  }
  
  // ANIMATION
  &__dots-ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    overflow: hidden;
    margin-top: 3rem;
  }

  &__dots-scroller {
    display: flex;
    animation: scroll 80s linear infinite;
    width: calc(80vw * 4);
  }

  &__dots-divider {
    width: 80vw;
    margin-right: 2px;
    position: relative;
    left: -80vw;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(80vw * 2));
  }
}