@import "./../styles/variables.scss";

.contact {
  background: #f7e5e7;
  height: 80vh;

  &__wrapper {
    width: 80%;
    margin: 0 10%;
    color: #2d2d2d;
  }

  & h1 {
    margin: 0;
    padding-top: 10rem;
    text-align: center;
    font-family: "Tropiline";
    font-size: 26px;
    font-weight: bold;
  }

  & p {
    text-align: center;
    font-family: "Tropiline";
    font-size: 18px;
  }

  & a {
    font-size: 18px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #2d2d2d;
    border-radius: 3px;
    padding-left: 30px;
    padding-right: 30px;
  }

  &__button {
    border: 1px solid #2d2d2d;
    font-family: "Tropiline";
    max-width: 150px;
    border-radius: 3px;
    padding: 13px 10px 10px 10px;
    display: flex;
    justify-content: center;
    color: #2d2d2d;
    text-decoration: none;
    margin: 1rem 0rem;
    z-index: 1;
    transition: all 0.5s ease-in-out;
  }

  &__button:hover {
    background: #2d2d2d;
    color: #f7e5e7;
  }
}

.profile {
  &__footer {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    background: #1c1c1c;
    height: 200px;
    padding: 0 0 1rem 0;
  }

  &__footer-txt {
    color: white;
    font-family: "Tropiline Light";
    opacity: 0.8;
    font-size: 10pt;
  }
}