@import "./../styles/variables.scss";

.other-projects {
  
  &__page-wrapper {
    display: flex;
    flex-direction: column;
    background: #f7e5e7;
    display: flex;
    z-index: -2;
  }

  &__card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
  }

  &__card {
    width: 75%;
    border-radius: 1px;
    margin-bottom: 40px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background: linear-gradient( rgba(67, 74, 164, 0.61), rgba(18, 95, 119, 0.432) ), url(../assets/images/other-projects/neighbourhood.jpg);
    background-size: cover;
    background-position: center;


    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }

    @media only screen and (min-width: 1024px) {
      max-width: 800px;
      justify-content: space-between;
    }
  }

  &__card-ynot {
    width: 75%;
    border-radius: 1px;
    margin-bottom: 40px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background: linear-gradient( rgba(36, 44, 158, 0.665), rgba(18, 51, 100, 0.535) ), url(../assets/images/other-projects/ynot.jpg);
    background-size: cover;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }

    @media only screen and (min-width: 1024px) {
      max-width: 800px;
      justify-content: space-between;
    }
  }

  &__card-details {
    color: white;
    font-family: Tropiline;
    margin-bottom: 20px;
    padding: 0 20px;

    @media only screen and (min-width: 768px) {
      width: 50%;
      max-width: 350px;
      margin-bottom: 0;
      padding: 1rem;
    }
  }

  &__card-title {
    margin: 0;
    margin-bottom: 0rem 0rem 0rem 1rem;
    color:rgba(45, 45, 45, 0.85);
    color:#f7e5e7;

  }

  &__card-paragraph {
    margin: 0;
    font-size: 10pt;
    color:rgba(45, 45, 45, 0.85);
    color:#f7e5e7;
  }

  &__card-img-container {
    font-size: 10pt;
    width: 300px;
    border-radius: 3px;

    @media only screen and (min-width: 768px) {
      width: 350px;
    }
  }

  &__card-image-wrapper {
    width: 100%;
  }

  &__card-image {
    width: 90%;
    background: #f7e5e7c5;
    padding: 1rem;
  }

  &__button {
    border: 1px solid #f7e5e7;
    color:#f7e5e7;
    font-family: "Tropiline";
    max-width: 150px;
    border-radius: 3px;
    padding: 13px 10px 10px 10px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin: 1rem 0rem;
    z-index: 1;
    transition: all 0.5s ease-in-out;
  }

  &__button:hover {
    color: rgba(45, 45, 45, 0.85);
    background:#f7e5e7;

  }
}

.profile {
  &__footer {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    background: #1c1c1c;
    height: 200px;
    padding: 0 0 1rem 0;
  }

  &__footer-txt {
    color: white;
    font-family: "Tropiline Light";
    opacity: 0.8;
    font-size: 10pt;
  }
}