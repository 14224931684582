@import '../styles/variables.scss';

.navigation {
    position: fixed;
    z-index: 1000;
    top: 1rem;
    left: 1rem;

    &__menu {
        width: 80%; 

        & > ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    &__subnav-btn {
        &:hover {
            cursor: pointer;
        }
    }

    &__exit {
        width: 30px;
        margin: 1rem;
    }

    &__link {
        font-family:"Tropiline Black";
        font-size: 18pt;
        margin: 1rem 0rem;
        color: #2d2d2d;
    }

    &__link:hover {
        transform: scale(1.006);
        opacity: 0.9;
        transition: all 0.1s ease-in-out;
    }

    &__link-light {
        font-family:"Tropiline Light";
        font-size: 18pt;
        margin: 1rem 0rem;
        color: #2d2d2d;
    }

    &__link-light:hover {
        transform: scale(1.006);
        opacity: 0.9;
        transition: all 0.1s ease-in-out;
    }

}

li {
    list-style-type: none;

}

a {
    text-decoration: none;
    color: unset;
}

a  :visited {
    text-decoration: none;
    color: unset;
}