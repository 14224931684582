@import "./../styles/variables.scss";

.rally {
  &__page-wrapper {
    background: url("../assets/images/Rally-titleimage.png");
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    z-index: -2;
  }

  &__outline {
    width: 100%;
    display: flex;
    z-index: 10;
    border: rgb(255, 224, 249) solid 2px;
    margin: 1rem 1rem;
  }

  &__info-ctn {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    width: 50%;
  }

  &__info-ctn-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28% 0%;
    justify-content: center;
    z-index: 10;
    border: 1px solid rgba(252, 224, 255, 0.6);
    background: rgba(252, 224, 255, 0.639);
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    &__info-ctn {
      width: 70%;
    }
  }

  @media screen and (max-width: 525px) {
    &__info-ctn {
      width: 85%;
    }
  }

  &__title {
    color: #023faa;
    font-family: "Tropiline Black";
    font-size: 24pt;
    margin: 1rem;
    text-align: center;
  }
  &__button {
    font-family: "Tropiline";
    border: 1px solid #023faa;
    border-radius: 3px;
    padding: 13px 10px 10px 10px;
    display: flex;
    justify-content: center;
    color: #023faa;
    text-decoration: none;
    margin: 1rem;
    z-index: 1;
  }

  &__button:hover {
    background: #023faa;
    color: rgba(252, 224, 255, 0.6);
    transition: all 0.5s ease-in-out;
  }

  &__subtitle {
    color: #023faa;
    font-family: "Tropiline";
    font-size: 16pt;
    text-align: center;
    line-height: 22px;
    width: 70%;
  }

  @media screen and (max-width: 425px) {
    &__paragraph {
      font-size: 11pt;
    }
  }

  &__page-content {
    width: 100%;
    background-image: linear-gradient(#e2b8da9e, #bfb8e291);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__video {
    max-width: 300px;
    margin: 2rem;
  }

  @media screen and (max-width: 598px) {
    &__video {
      margin: 1rem 0rem;
      width: 90%;
    }
  }

  &__rally {
    font-family: "Tropiline Black";
    color: #023faa;
    font-size: 20pt;
    margin-bottom: 1rem;
    line-height: 29px;
    text-align: center;
    margin-top: 1rem;
  }

  &__intro-ctn {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }

  @media screen and (max-width: 726px) {
    &__intro-ctn {
      margin: 3rem 0rem 1rem 0rem;
    }
  }

  &__intro {
    color: #023faa;
    font-family: "Tropiline";
    font-size: 13pt;
    line-height: 22px;
    padding: 10px 0rem;
  }

  &__video-def {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__page-content-2 {
    width: 100%;
    background-image: linear-gradient(#bfb8e291, #e2b8da9e);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__info-ctn-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0rem 1rem 4rem 1rem;
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    &__info-ctn-1 {
      width: 90%;
    }
  }

  &__marketing-ctn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }

  &__marketing-img {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    &__marketing-img {
      width: 100%;
    }
  }

  &__paragraph {
    color: #023faa;
    font-family: "Tropiline";
    font-size: 12pt;
    line-height: 22px;
    text-align: center;
  }

  &__dots-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    overflow: hidden;
    margin-top: 3rem;
  }

  &__dots-scroller {
    display: flex;
    animation: scroll 80s linear infinite;
    width: calc(80vw * 4);
  }

  &__dots-divider {
    width: 80vw;
    margin-right: 2px;
    position: relative; 
    left: -80vw;
  }

  &__why-rally-ctn {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }

  &__footer {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #1c1c1c;
    height: 200px;
  }

  &__scroll-down {
    position: absolute;
    bottom: 0%;
    right: 46%;
    animation: bounce 1s infinite;
    z-index: 11;
    background: none;
    border: none;
  }

  &__scroll-img {
    width: 20px;
    height: 20px;
    padding: 2rem;
  }

  &__scroll-img {
    width: 20px;
    height: 20px;
    padding: 2rem;
  }

  &__other-projects-ctn {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8rem;
    justify-content: center;
  }

  &__project-ctn {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: white;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__project-ctn-kepler {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(rgba(164, 67, 148, 0.333), rgba(191, 184, 226, 0.363)), url("../assets/images/other-projects/project-kepler.jpg");
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color:white;
    transition: all 0.2s ease-in-out;

  }

  &__project-ctn-kepler:hover {
    transform: scale(1.1);
  }

  &__project-ctn-communihelp {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(rgba(164, 67, 148, 0.333), rgba(191, 184, 226, 0.363)), url("../assets/images/other-projects/project-communihelp.jpg");
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color:white;
    transition: all 0.2s ease-in-out;

  }

  &__project-ctn-communihelp:hover {
    transform: scale(1.1);
  }

  &__project-ctn-orca {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(rgba(164, 67, 148, 0.333), rgba(191, 184, 226, 0.363)), url("../assets/images/other-projects/project-orca.jpg");
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color:white;
    transition: all 0.2s ease-in-out;

  }

  &__project-ctn-other:hover {
    transform: scale(1.1);
  }

  &__project-ctn-other {
    margin: 2rem 1rem;
    display: flex;
    height: 200px;
    width: 200px;
    background: linear-gradient(rgba(164, 67, 148, 0.333), rgba(191, 184, 226, 0.363)), url("../assets/images/other-projects/project-other.jpg");
    background-size: cover;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color:white;
    transition: all 0.2s ease-in-out;
  }

  &__project-ctn-orca:hover {
    transform: scale(1.1);
  }


  &____project-title{
    z-index: 10;
  }

  &__project-1-background-image{
    z-index: -10;
  }

  &__project-title {
    font-family: "Tropiline Black";
    
  }

}


.rally {
  &__project-ctn-rally:hover .rally__project-title {
    transform: translateX(20px);
  }
}

.kepler__project-ctn-rally:hover .rally__project-info-ctn {
  transform: translateY(-8px);
}

.kepler__project-ctn-orca:hover .rally__project-info-ctn {
  transform: translateY(-8px);
}

.kepler__project-ctn-communihelp:hover .rally__project-info-ctn {
  transform: translateY(-8px);
}

.kepler__project-ctn-other:hover .rally__project-info-ctn {
  transform: translateY(-8px);
}

.rally__project-info-ctn {
  transition: all ease-in-out 0.2s;
}


video {
  object-fit: unset;
  width: unset;
  height: unset;
  position: unset;
  top: unset;
  left: unset;
}

.para-2 {
  font-family: "Tropiline";
  margin-top: 1rem;
}

// ANIMATIONS

@keyframes scroll {
  0% { transform: translateX(0); }
	100% { transform: translateX(calc(80vw * 2))}
}

// CIRCLES

.row-itm {
  display: flex;
  width: 300px;
  height: 300px;
  justify-content: center;
}

.card-container {
  display: flex;
  justify-content: center;
  // height: 560px;
  // width: 1350px;
}

@media screen and (max-width: 1023px) {
  .card-container {
    flex-wrap: wrap;
  }
}

.circle-container {
  position: relative;
  text-align: center;
}

.circle-container__hover {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  width: 250px;
  height: 250px;
  padding: 0px 0 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transition: 0.3s;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(2, 64, 170, 0.37);
}

.circle-container__hover > h2,
.circle-container__hover > p {
  opacity: 1;
}

.circle-container__hover-overlay {
  position: absolute;
  width: 250px;
  height: 250px;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  background: rgba(2, 64, 170, 0.45);
  color: transparent;
  opacity: 0;
}

.circle-container__hover:hover .circle-container__hover-overlay {
  background: rgba(2, 64, 170, 0.55);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container__hover:hover h2,
.circle-container__hover:hover p {
  opacity: 0;
}

.circle-container__hover:hover {
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(0, 0, 0, 0);
  transform: scale(1.3);
}

.homepage-icons--hover {
  padding: 1rem;
  margin: 0.5rem 0rem;
  height: 88px;
  opacity: 0;
  -webkit-transition: 2s;
  -moz-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
}

.hover-overlay-text {
  opacity: 0;
  font-family: "Tropiline Light";
  line-height: 17px;
  font-size: 13px;
  padding: 0 30px;
  color: #fff;
  transition: 2s;
  -webkit-transition: 2s;
  -moz-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
}

.circle-container__hover:hover .homepage-icons--hover,
.circle-container__hover:hover .hover-overlay-text {
  opacity: 1;
}

@media screen and (max-width: 1023px) {
  .circle-container__hover-overlay {
    display: none;
  }

  .homepage-text--desktop {
    display: none;
    font-family: "Tropiline Light";
    color: #023faa;
  }

  .homepage-text--desktop-bold {
    display: none;
    font-family: "Tropiline Black";
    color: #023faa;
  }

  .homepage-text--mobile {
    display: flex;
    font-family: "Tropiline Light";
    color: #023faa;
    font-size: 11pt;
    width: 66%;
    line-height: 19px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .homepage-text--mobile-bold {
    display: block;
    font-family: "Tropiline Black";
    color: #023faa;
    font-size: 11pt;
    width: 66%;
    line-height: 19px;
  }
}

@media screen and (min-width: 1024px) {
  .homepage-text--desktop {
    display: block;
    font-family: "Tropiline Light";
    color: #023faa;
  }

  .homepage-text--mobile {
    display: none;
    font-family: "Tropiline Light";
    color: #023faa;
  }
  .homepage-text--mobile-bold {
    display: none;
    font-family: "Tropiline Black";
    color: #023faa;
  }
}

@media screen and (min-width: 1024px) {
  .homepage-text--desktop-bold {
    display: block;
    font-family: "Tropiline Black";
    color: #023faa;
  }
}
